import {
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material';

const style = {
  height: '2px',
  // width: '100%',
  borderRadius: '30px',
};

interface ICustomProgress extends LinearProgressProps {
  barcolor: string;
  position?: string;
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  width?: string;

  customzindex?: string;
}

/**
 * Прогресс бар лимита открытия кейса
 */

export const CustomProgress = (props: ICustomProgress) => {
  return (
    <LinearProgress
      {...props}
      sx={{
        ...style,
        width: `${props.width || '100%'} `,
        position: `${props.position}`,
        top: `${props.top}`,
        bottom: `${props.bottom}`,
        right: `${props.right}`,
        left: `${props.left}`,
        ...(props.customzindex && { zIndex: props.customzindex }),
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#2B304C',
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: '30px',
          backgroundColor: props.barcolor,
        },
      }}
    />
  );
};
