// ParticipantInfo.js
import React from 'react';
import styles from './ParticipantInfo.module.scss';

interface ParticipantInfoProps {
  active: boolean;
  noActive?: boolean;
  end?: boolean;
}

const ParticipantInfo: React.FC<ParticipantInfoProps> = ({
  active,
  noActive,
  end,
}) => {
  return (
    <>
      {active ? (
        <div className={styles.container_participant}>
          <p> Участник </p>
        </div>
      ) : end ? (
        <div className={styles.container_participant}>
          <p> Завершено </p>
        </div>
      ) : !active && noActive && !end ? (
        <div className={styles.container_participant}>
          <p> Не участник </p>
        </div>
      ) : null}
    </>
  );
};

export default ParticipantInfo;
