import info from '@/assets/icons/info-circle.svg';
import { caseBgGradients } from '@/configs/caseBgGradients';
import {
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  MpLootboxesLootboxModel,
  useClosestGiveawayLazyQuery,
} from 'generated/projectlb-hasura';
import Image from 'next/image';
import { Case } from '../Case/Case';
import styles from './CaseSection.module.scss';
import { useAtomValue } from 'jotai';
import { menuAtom } from 'atoms/menu-height-atom';
import { FC, useEffect } from 'react';
import ChallengeDropCard from '@/components/Layout/ChallengeDrop/СhallengeDropCard/ChallengeDropCard';
import giveAway from '@/assets/images/give/giveAway.webp';
import CentreButton from '@/components/styled/CustomButtons/CentreButton/CentreButton';
import { theme } from '@/styles/theme';
import { APP_URLS } from '@/configs/urls';
import { useRouter } from 'next/router';

interface CaseSectionProps {
  tooltipText?: string | null;
  title: string;
  cases: MpLootboxesLootboxModel[];
  id: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#191f40',
    borderRadius: '6px',
    padding: '6px 6px',
    color: '#ffffff',
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: `'Inter', 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols', sans-serif`,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#191f40',
  },
}));

/**
 * Сетка всех кейсов
 * @param title название секции с кейсами
 * @param cases массив кейсов
 */
const CaseSection: FC<CaseSectionProps> = (props) => {
  const { height: scrollOffset } = useAtomValue(menuAtom);

  const mobile = useMediaQuery(theme.breakpoints.down(501));

  const router = useRouter();
  const [ClosestGiveawayQuery, { data: ClosestGiveaway }] =
    useClosestGiveawayLazyQuery();
  useEffect(() => {
    ClosestGiveawayQuery();
  }, [router.asPath]);

  const handleClick = () => {
    router.push(
      APP_URLS.getGiveawayPage(
        ClosestGiveaway?.ClosestGiveaway?.giveaway?.giveaway_id!
      )
    );
  };
  return (
    <section
      className={styles.container}
      id={props.id}
      style={{ scrollMarginTop: scrollOffset }}
    >
      <div className={styles.textContainer}>
        <Typography className={styles.text}>{props.title}</Typography>
        {props.tooltipText && (
          <LightTooltip
            title={
              <Typography className={styles.tooltip_text}>
                {props.tooltipText}
              </Typography>
            }
            arrow
            enterTouchDelay={0}
          >
            <div className={styles.info_icon}>
              <Image src={info} alt="Info" />
            </div>
          </LightTooltip>
        )}
      </div>

      <div className={styles.grid}>
        {props.cases.map((lootbox, index) => {
          return (
            <Case
              key={lootbox.id}
              id={lootbox.id!}
              section={props.title}
              badge={lootbox.badges}
              slug={lootbox.slug}
              imageLink={lootbox.image_link}
              price={lootbox.price}
              title={lootbox.name}
              openCount={lootbox.open_count}
              openMaxCount={lootbox.open_max_count}
              color={
                caseBgGradients[
                  Math.floor(
                    Math.sin(index) * Math.sin(index) * caseBgGradients.length
                  )
                ]
              }
            />
          );
        })}
        {props.id === 'casecat_novelties' &&
          ClosestGiveaway?.ClosestGiveaway?.giveaway?.giveaway_id &&
          (!mobile ? (
            <div className={styles.bannerGive}>
              <div className={styles.leftSide}>
                <div className={styles.leftSideContent}>
                  <Image src={giveAway} />
                  <p>Участвуй в розыгрышах и забирай подарки</p>
                  <CentreButton
                    variant="outlined"
                    color="mainGreen"
                    className={styles.buttonWithTextContainer__button}
                    text={`Участвовать`}
                    onClick={() => handleClick()}
                  />
                </div>
              </div>
              {/* {ClosestGiveaway?.ClosestGiveaway?.giveaway?.total_seconds > 0 ? ( */}
              <div className={styles.rightSide}>
                <ChallengeDropCard
                  key={ClosestGiveaway?.ClosestGiveaway?.giveaway?.giveaway_id!}
                  giveawayId={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.giveaway_id!
                  }
                  start_date={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.start_date
                  }
                  end_date={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.end_date
                  }
                  weaponName={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item?.item_name
                  }
                  skinName={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item?.item_name
                  }
                  price={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item?.item_price
                  }
                  image={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item
                      ?.item_image_link
                  }
                  rarity={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item
                      ?.item_rarity
                  }
                  quality={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item
                      ?.item_quality!
                  }
                  totalSecond={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.total_seconds!
                  }
                />
              </div>
              {/* ) : (
                ''
              )} */}
            </div>
          ) : (
            <div className={styles.bannerGiveMobile}>
              <div className={styles.giveAway}>
                <Image src={giveAway} />
              </div>

              <div className={styles.giveCard}>
                <ChallengeDropCard
                  key={ClosestGiveaway?.ClosestGiveaway?.giveaway?.giveaway_id!}
                  giveawayId={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.giveaway_id!
                  }
                  start_date={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.start_date
                  }
                  end_date={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.end_date
                  }
                  weaponName={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item?.item_name
                  }
                  skinName={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item?.item_name
                  }
                  price={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item?.item_price
                  }
                  image={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item
                      ?.item_image_link
                  }
                  rarity={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item
                      ?.item_rarity
                  }
                  quality={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.item
                      ?.item_quality!
                  }
                  totalSecond={
                    ClosestGiveaway?.ClosestGiveaway?.giveaway?.total_seconds!
                  }
                />
              </div>
              <div className={styles.footerBanner}>
                <p>Участвуй в розыгрышах и забирай подарки</p>

                <CentreButton
                  variant="outlined"
                  color="mainGreen"
                  className={styles.buttonWithTextContainer__button}
                  text={`Участвовать`}
                  onClick={() => handleClick()}
                />
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default CaseSection;
